document.addEventListener('DOMContentLoaded', function () {
  const slidesWrapper = document.querySelector(
    '.done-page-stories-slider-slides'
  );
  const slides = document.querySelectorAll('.done-page-stories-slide');
  const bullets = document.querySelectorAll(
    '.done-page-stories-pagination-bullet'
  );
  const lines = document.querySelectorAll('.done-page-stories-pagination-line');
  const totalSlides = slides.length - 2;
  let currentIndex = 0;
  let slideInterval;
  let isIntervalActive = false;

  let touchstartX = 0;
  let touchstartY = 0;

  let largeDevices = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      '--done-large-devices'
    ),
    10
  );

  function isHorizontalLayout() {
    return window.innerWidth <= largeDevices;
  }

  function getSlideDimension() {
    return isHorizontalLayout()
      ? slides[0].clientWidth
      : slides[0].clientHeight;
  }

  slidesWrapper.style.transition = 'transform 1s ease';

  function updateBullets(index) {
    bullets.forEach((bullet, i) => {
      if (i < index) {
        bullet.classList.add('completed');
        bullet.classList.remove('active');
      } else if (i === index) {
        bullet.classList.add('active');
        bullet.classList.remove('completed');
      } else {
        bullet.classList.remove('completed', 'active');
      }
    });

    lines.forEach((line, i) => {
      if (i < index) {
        line.classList.add('active');
      } else {
        line.classList.remove('active');
      }
    });
  }

  function goToNextSlide() {
    currentIndex++;
    const slideDimension = getSlideDimension();

    if (isHorizontalLayout()) {
      slidesWrapper.style.transform = `translateX(-${
        currentIndex * slideDimension
      }px)`;
    } else {
      slidesWrapper.style.transform = `translateY(-${
        currentIndex * slideDimension
      }px)`;
    }

    if (currentIndex > totalSlides) {
      updateBullets(0);
      setTimeout(() => {
        slidesWrapper.style.transition = 'none';
        slidesWrapper.style.transform = 'translate(0, 0)';
        currentIndex = 0;

        setTimeout(() => {
          slidesWrapper.style.transition = 'transform 1s ease';
        }, 20);
      }, 1000);
    } else {
      updateBullets(currentIndex);
    }
  }

  function goToSlide(index) {
    currentIndex = index;
    const slideDimension = getSlideDimension();
    slidesWrapper.style.transition = 'transform 1s ease';

    if (isHorizontalLayout()) {
      slidesWrapper.style.transform = `translateX(-${
        currentIndex * slideDimension
      }px)`;
    } else {
      slidesWrapper.style.transform = `translateY(-${
        currentIndex * slideDimension
      }px)`;
    }

    updateBullets(currentIndex);
  }

  function startSlideInterval() {
    if (!isIntervalActive) {
      slideInterval = setInterval(goToNextSlide, 4000);
      isIntervalActive = true;
    }
  }

  function stopSlideInterval() {
    clearInterval(slideInterval);
    isIntervalActive = false;
  }

  document.addEventListener('touchstart', (e) => {
    touchstartX = e.changedTouches[0].screenX;
    touchstartY = e.changedTouches[0].screenY;
  });

  document.addEventListener('touchend', (e) => {
    const touchendX = e.changedTouches[0].screenX;
    const touchendY = e.changedTouches[0].screenY;

    if (isHorizontalLayout()) {
      if (touchendX < touchstartX && currentIndex < totalSlides) {
        goToNextSlide();
      } else if (touchendX > touchstartX && currentIndex > 0) {
        currentIndex--;
        goToSlide(currentIndex);
      }
    } else {
      if (touchendY < touchstartY && currentIndex < totalSlides) {
        goToNextSlide();
      } else if (touchendY > touchstartY && currentIndex > 0) {
        currentIndex--;
        goToSlide(currentIndex);
      }
    }
  });

  bullets.forEach((bullet, index) => {
    bullet.addEventListener('click', () => {
      goToSlide(index);
      stopSlideInterval();
      document.activeElement.blur();
    });

    bullet.addEventListener('focus', () => {
      goToSlide(index);
      stopSlideInterval();
    });

    bullet.addEventListener('blur', () => {
      startSlideInterval();
    });
  });

  slidesWrapper.addEventListener('mouseenter', stopSlideInterval);
  slidesWrapper.addEventListener('mouseleave', () => {
    if (!document.querySelector('.done-page-stories-pagination-bullet:focus')) {
      startSlideInterval();
    }
  });

  startSlideInterval();
  updateBullets(currentIndex);

  window.addEventListener('resize', () => {
    const slideDimension = getSlideDimension();

    if (isHorizontalLayout()) {
      slidesWrapper.style.transform = `translateX(-${
        currentIndex * slideDimension
      }px)`;
    } else {
      slidesWrapper.style.transform = `translateY(-${
        currentIndex * slideDimension
      }px)`;
    }
  });
});
